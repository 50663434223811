<template>
<v-row class="toolBar">
  <v-col md="2">
    <v-progress-circular v-if="loading && canBack" indeterminate color="colorPrimary"></v-progress-circular>

    <v-icon
      v-if="canBack"
      class="utilButton"
      @click="$emit('onBack')"
      :disabled="loading">
      mdi-arrow-left
    </v-icon>
  </v-col>

  <v-col class="pt-0 pb-0" style="text-align: center;" md="8">
    <v-toolbar-title class="toolBar-text">
      {{ `${actionName()} ${title}`}}
    </v-toolbar-title>
  </v-col>

  <v-col md="2" class="pt-0 pb-0 text-right mt-1">
    <CloseButton :loading="loading" @onClose="$emit('onClose')"/>
  </v-col>
</v-row>
</template>

<script>
export default {
  name: 'OrganizzeModalHeader',
  props: {
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    canBack: {
      type: Boolean,
      default: false,
      required: false
    },
    isEdit: {
      type: Boolean,
      default: false,
      required: false
    },
    isDuplicate: {
      type: Boolean,
      default: false,
      required: false
    },
    isDelete: {
      type: Boolean,
      default: false,
      required: false
    },
    title: {
      type: String,
      default: '???',
      required: false
    }
  },
  directives: {},
  components: {
    CloseButton: () => import('@/views/components/organizze/closeButton')
  },
  data: () => ({}),
  created () {},
  mounted () {},
  computed: {},
  watch: {},
  methods: {
    actionName () {
      if(this.isDelete) return 'Excluir'
      if(this.isDuplicate) return 'Duplicar'
      return 'Editar'
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .toolBar {
    background-color: $colorPrimary !important;
    border-radius: 16px !important;
    display: flex;
    justify-content: center;
    margin: 0px;
    color: white;

    &-text {
      padding: 5px 0;
      font-weight: bold;
      text-transform: uppercase;
      @media(max-width:400px) {
        font-size: 16px;
      }
    }
  }

  .utilButton {
    color: $colorPrimaryDarken;
    border: 3px solid $colorPrimaryDarken;
    border-radius: 16px;


    &:hover {
      color: white;
      border: 3px solid white;
    }
  }
</style>